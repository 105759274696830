import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-108b0f76"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "column"
};
const _hoisted_2 = {
  key: 1,
  class: "position-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Operation = _resolveComponent("Operation");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_Grid = _resolveComponent("Grid");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_ArrowLeftBold = _resolveComponent("ArrowLeftBold");
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("section", null, [$props.iconVisible ? (_openBlock(), _createBlock(_component_el_dropdown, {
    key: 0,
    trigger: "click",
    style: {
      "float": "right",
      "margin-top": "-40px"
    }
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_el_scrollbar, {
        height: "400px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tree, {
          data: $props.table.tableFields,
          props: $data.defaultProps,
          draggable: "",
          "allow-drop": $options.handleDrop,
          onNodeDrop: $options.dropOk
        }, {
          default: _withCtx(({
            node,
            data
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Grid)]),
            _: 1
          }), _createElementVNode("span", _hoisted_1, _toDisplayString(node.label), 1), _createVNode(_component_el_switch, {
            modelValue: data.show,
            "onUpdate:modelValue": $event => data.show = $event,
            onChange: _cache[0] || (_cache[0] = $event => $options.changeStatus($props.table.tableFields))
          }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_el_icon, {
            style: _normalizeStyle({
              color: $options.getIconColor(data, 'left')
            }),
            onClick: $event => $options.toFixedPosition('left', node, $props.table.tableFields)
          }, {
            default: _withCtx(() => [_createVNode(_component_ArrowLeftBold)]),
            _: 2
          }, 1032, ["style", "onClick"]), _createVNode(_component_el_icon, {
            style: _normalizeStyle({
              color: $options.getIconColor(data, 'right')
            }),
            onClick: $event => $options.toFixedPosition('right', node, $props.table.tableFields)
          }, {
            default: _withCtx(() => [_createVNode(_component_ArrowRightBold)]),
            _: 2
          }, 1032, ["style", "onClick"])]),
          _: 1
        }, 8, ["data", "props", "allow-drop", "onNodeDrop"])]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_el_button, {
      link: "",
      type: "primary"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Operation)]),
        _: 1
      }), _createTextVNode(" 显示列 ")]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_el_table, {
    key: $data.tableKey,
    border: "",
    data: $props.table.tableData,
    onSelectionChange: $options.handleSelectionChange
  }, {
    default: _withCtx(() => [$props.selectionVisible ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      type: "selection",
      width: "55"
    })) : _createCommentVNode("", true), $props.indexVisible ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 1,
      label: "序号",
      type: "index",
      align: "center",
      width: "80px"
    })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.table.tableFields, item => {
      return _openBlock(), _createElementBlock(_Fragment, null, [item.show ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: item.prop,
        prop: item.prop,
        label: item.label,
        fixed: item.fixed,
        width: $options.getWidth(item.width),
        align: "center",
        "show-overflow-tooltip": item.show_overflow_tooltip
      }, _createSlots({
        _: 2
      }, [item.prop === 'label' ? {
        name: "default",
        fn: _withCtx(scope => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row[item.prop], label => {
          return _openBlock(), _createBlock(_component_el_tag, {
            key: label,
            closable: "",
            effect: "plain",
            style: {
              "width": "80px"
            },
            onClose: $event => $options.deleteLabel(label.id, scope.row.id)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(label.name), 1)]),
            _: 2
          }, 1032, ["onClose"]);
        }), 128))]),
        key: "0"
      } : {
        name: "default",
        fn: _withCtx(scope => [item.prop === 'exception_handling_desc' || item.prop === 'sync_state_desc' ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle({
            color: $options.changeContentColor(item.prop, scope.row[item.prop.replace('_desc', '')])
          })
        }, _toDisplayString(scope.row[item.prop]), 5)) : item.prop === 'stay_time' || item.prop === 'receipt_days' || item.prop === 'delivery_days' ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle({
            color: $options.changeContentColor(item.prop, scope.row[item.prop])
          })
        }, _toDisplayString(scope.row[item.prop]), 5)) : (_openBlock(), _createElementBlock("span", {
          key: 2
        }, _toDisplayString(scope.row[item.prop]), 1))]),
        key: "1"
      }]), 1032, ["prop", "label", "fixed", "width", "show-overflow-tooltip"])) : _createCommentVNode("", true)], 64);
    }), 256)), $props.operationVisible ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 2,
      fixed: "right",
      label: "操作",
      width: "150px",
      align: "center"
    }, {
      default: _withCtx(scope => [_renderSlot(_ctx.$slots, "default", {
        row: scope.row
      }, undefined, true)]),
      _: 3
    })) : _createCommentVNode("", true)]),
    _: 3
  }, 8, ["data", "onSelectionChange"])), $props.paginationVisible ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.dataTotal > $data.listPagination.page_size ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    "current-page": $data.listPagination.current_page,
    "page-size": $data.listPagination.page_size,
    "page-sizes": [10, 20, 30, 40],
    small: "",
    background: "",
    layout: "total, sizes, prev, pager, next, jumper",
    total: $data.dataTotal,
    onSizeChange: $options.handleSizeChange,
    onCurrentChange: $options.handleCurrentChange
  }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}