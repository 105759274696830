import { Grid, Operation, ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
export default {
  components: {
    Grid,
    Operation,
    ArrowLeftBold,
    ArrowRightBold
  },
  props: {
    table: {
      type: Object,
      default: null
    },
    pagination: {
      type: Object,
      default: null
    },
    total: {
      type: Number,
      default: 0
    },
    iconVisible: {
      type: Boolean,
      default: true
    },
    indexVisible: {
      type: Boolean,
      default: false
    },
    selectionVisible: {
      type: Boolean,
      default: true
    },
    operationVisible: {
      type: Boolean,
      default: true
    },
    paginationVisible: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['change-pagination', 'get-selected-ids', 'get-deleted-id'],
  data() {
    return {
      tableKey: 1,
      defaultProps: {
        label: 'label'
      },
      dataTotal: this.total,
      listPagination: this.pagination,
      ids: []
    };
  },
  watch: {
    pagination(val) {
      this.listPagination = val;
    },
    total(val) {
      this.dataTotal = val;
    }
  },
  methods: {
    getIconColor(data, fixed) {
      return data.fixed === fixed ? data.color : '';
    },
    handleDrop(draggingNode, dropNode, type) {
      return type !== 'inner';
    },
    dropOk() {
      this.tableKey++;
    },
    changeStatus(arr) {
      localStorage.setItem('logistics-column', JSON.stringify(arr));
    },
    // 获取选中的row
    handleSelectionChange(val) {
      this.ids = val.map(item => {
        return item.id;
      });
      this.$emit('get-selected-ids', this.ids);
    },
    // 固定表格列
    toFixedPosition(str, node, table) {
      let {
        fixed
      } = node.data;
      if (fixed && fixed === str) {
        node.data.fixed = false;
        node.data.color = '';
      } else {
        node.data.fixed = str;
        node.data.color = '#409eff';
      }
      this.changeStatus(table);
    },
    // 标注异常处理情况(待处理)、获取状态(失败)和最新轨迹停留时长(>5天)
    changeContentColor(prop, val) {
      if (prop === 'exception_handling_desc' && val === 10 || prop === 'sync_state_desc' && val === 20 || prop === 'stay_time' && parseFloat(val) >= 5 || prop === 'receipt_days' && val === -1 || prop === 'delivery_days' && val === -1) {
        return '#EA1D1D';
      } else if (prop === 'exception_handling_desc' && val === 0) {
        return '#D7D7D7';
      }
    },
    handleCurrentChange(val) {
      this.listPagination.current_page = val;
      this.$emit('change-pagination', this.pagination);
    },
    handleSizeChange(val) {
      this.listPagination.page_size = val;
      this.listPagination.current_page = 1;
      this.$emit('change-pagination', this.pagination);
    },
    deleteLabel(labelId, waybillId) {
      this.$emit('get-deleted-id', {
        id: waybillId,
        label_id: labelId
      });
    },
    getWidth(width) {
      if (this.type) {
        return '';
      }
      if (width) {
        return width;
      } else {
        return '200px';
      }
    }
  }
};