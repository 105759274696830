import { Search } from '@element-plus/icons-vue';
import BaseOption from '../../../common/base-option-dialog.vue';
import Error from '../common/base-error-table.vue';
export default {
  components: {
    Search,
    Error,
    BaseOption
  },
  data() {
    return {
      table: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      keyword: '',
      skuId: 0,
      skuType: '',
      skuVisible: false,
      errorVisible: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入'
        }]
      },
      error: {}
    };
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.clearAll();
      }
    }
  },
  mounted() {
    this.getSku();
  },
  methods: {
    async getSku() {
      try {
        await this.$store.dispatch('system/base/getSku', {
          params: {
            current_page: this.pagination.current_page,
            page_size: this.pagination.page_size,
            name: this.keyword
          }
        });
        let sku = this.$store.state.system.base;
        this.table = {
          tableData: sku.sku,
          tableFields: [{
            label: 'ID',
            prop: 'id',
            width: '90px',
            show: true
          }, {
            label: 'SKU名称',
            prop: 'name',
            width: '1030px',
            show: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            width: '400px',
            show: true
          }]
        };
        this.total = sku.skuLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getSku();
    },
    clearAll() {
      this.pagination = {
        current_page: 1,
        page_size: 10
      };
      this.getSku();
    },
    changePagination(val) {
      this.pagination = val;
      this.getSku();
    },
    showSkuDialog(type, id, name) {
      this.skuType = type;
      if (type === 'update') {
        this.skuId = id;
        this.form.name = name;
        this.formRules.name[1] = {
          pattern: /^[A-Za-z0-9_-]+$/,
          message: '只允许英文，数字，下划线，中划线'
        };
        this.formRules.name[2] = {
          min: 1,
          max: 30,
          message: '最多只允许输入30位'
        };
      } else {
        this.form.name = '';
        this.formRules.name[1] = {
          min: 1,
          max: 1000,
          message: '最多只允许输入1000位'
        };
      }
      this.skuVisible = true;
    },
    closeSku() {
      this.skuVisible = false;
      this.$refs.form.resetFields();
    },
    closeError() {
      this.errorVisible = false;
    },
    async createSku() {
      try {
        await this.$store.dispatch('system/base/createSku', {
          name: this.form.name
        });
        this.error = this.$store.state.system.base.skuError;
        if (JSON.stringify(this.error) === '{}') {
          this.errorVisible = false;
          this.skuVisible = false;
          this.$refs.form.resetFields();
          this.pagination.current_page = 1;
        } else {
          this.errorVisible = true;
        }
        this.getSku();
      } catch (err) {
        return;
      }
    },
    async updateSku() {
      try {
        await this.$store.dispatch('system/base/updateSku', {
          id: this.skuId,
          name: this.form.name
        });
        this.skuVisible = false;
        this.$refs.form.resetFields();
        this.getSku();
      } catch (err) {
        return;
      }
    },
    async submitSku() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.skuType === 'create') {
            if (this.form.name.length === 1000) {
              this.$message.warning('超出规定字数，请检查最后一项是否被截断');
            } else {
              this.createSku();
            }
          } else {
            this.updateSku();
          }
        }
      });
    }
  }
};