export default {
  props: {
    error: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      errorData: this.error
    };
  },
  watch: {
    error(val) {
      this.errorData = val;
    }
  },
  methods: {
    headerStyle({
      columnIndex
    }) {
      if (columnIndex === 0) {
        return {
          color: '#EA1D1D'
        };
      }
    }
  }
};