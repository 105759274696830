import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin-bottom": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("section", null, [_createElementVNode("div", _hoisted_1, _toDisplayString($props.error.success_count) + "条数据新增成功，以下" + _toDisplayString($props.error.fail_count) + "条数据新增失败 ", 1), _createVNode(_component_el_scrollbar, {
    height: "200px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      data: $data.errorData.errors,
      border: "",
      "header-cell-style": $options.headerStyle
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        label: "错误",
        prop: "msg",
        align: "center"
      }), _createVNode(_component_el_table_column, {
        label: $props.label,
        prop: "value",
        align: "center"
      }, null, 8, ["label"])]),
      _: 1
    }, 8, ["data", "header-cell-style"])]),
    _: 1
  })]);
}